import React from "react";
import { PrimaryNav } from "../../components";
import { Container, PageContainer, PageHeading2 } from "../../globalStyles";
import { podcasts_i_like } from "../../my_data/podcasts_i_like";

export default function PodcastsPage() {
  return (
    <PageContainer>
      <PrimaryNav currentPage="about" />
      <PageHeading2>Podcast Preferences</PageHeading2>
      <Container>
        <p>
          Majority of the media I consume is through podcasts - be it in audio
          of video format. Some selections from the podcasts I listen to/watch.
        </p>
        <p>
          Broadly speaking, I have divided the following list into two
          categories:
          <ul>
            <li>
              <b>Content-based</b> - Podcasts that I subscribe to for the
              content that they offer
            </li>
            <li>
              <b>Guest-based</b> - Conversational Podcasts that I listen to
              based on the guests they have that episode
            </li>
          </ul>
        </p>
      </Container>

      <div>
        {/* <h3>{podcasts_i_like.title}</h3> */}
        {/* <p>{podcasts_i_like.description}</p> */}
        {podcasts_i_like.categories.map(function (category, index) {
          return (
            <div>
              <h4 key={index}>{category.title}</h4>
              <p>{category.description}</p>
              <ul>
                {category.items.map(function (item, index) {
                  return (
                    <li key={index}>
                      {/* <a href={item.link} target="_blank" rel="noreferrer"> */}
                      {item.label}
                      {/* </a> */}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
}
