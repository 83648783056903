import styled from "styled-components/macro";

export const ProfileImage = styled.img`
  aspect-ratio: 1;
  width: 40%;
  border-radius: 5px;
  margin: 5px;

  @media screen and (max-width: 600px) {
    width: 70%;
  }
`;
