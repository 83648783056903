import { createGlobalStyle } from "styled-components";
import styled from "styled-components/macro";
import "./fonts.css";

export const GlobalStyle = createGlobalStyle`  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0;
    // background: teal;
    font-family: 'EB Garamond', serif;
    a {
      color: grey;
    }

    a:hover {
      color: blue;
    }

    div {
      width: 100%;
    }
  }
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;

  // background: teal;

  width: 90%;
  max-width: 768px;

  h1 {
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: normal;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
`;

export const PageBreak = styled.hr`
  width: 100%;
`;

export const PageHeading1 = styled.h1`
  width: 100%;
  font-size: xxx-large;
`;

export const PageHeading2 = styled.h1`
  width: 100%;
  font-size: xx-large;
`;
