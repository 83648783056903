import React from "react";
import { PageContainer, Container, PageHeading1 } from "../../globalStyles";
import { PrimaryNav } from "../../components";
import { projects_data } from "./projects_data";

export default function ProjectPage() {
  return (
    <PageContainer>
      <PrimaryNav currentPage="projects" />
      <PageHeading1>Projects</PageHeading1>
      <p>
        Note: Most of these projects are old and are not maintained anymore.
        More information about them can be found{" "}
        <a
          href="https://souruly.github.io/projects/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        on my previous webpage.<br></br>
        Also, although the P5 projects are listed below, the entire collection
        of them can be found{" "}
        <a
          href="https://souruly.github.io/P5-Playground/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <Container>
        <ul>
          {projects_data.projects.map(function (project, index) {
            return (
              <li key={index}>
                <a href={project.link} target="_blank" rel="noreferrer">
                  {project.name}
                </a>{" "}
                - <i>{project.date}</i>
              </li>
            );
          })}
        </ul>
      </Container>
      <PageHeading1>Reports and Publications</PageHeading1>
      <Container>
        <ul>
          {projects_data.reports.map(function (report, index) {
            return (
              <li key={index}>
                <a href={report.link} target="_blank" rel="noreferrer">
                  {report.name}
                </a>{" "}
                - <i>{report.date}</i>
              </li>
            );
          })}
        </ul>
      </Container>
    </PageContainer>
  );
}
