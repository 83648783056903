import React from "react";
import { PageContainer } from "../globalStyles";

export default function NoPage() {
  return (
    <PageContainer>
      <p>
        404, page not found. Please go back to <a href="/">Home</a>
      </p>
    </PageContainer>
  );
}
