export const music_i_like = {
  title: "Music I like",
  description:
    "Some selections from the music I listen to. The list isn't complete, and is rarely updated. I could link to some of my YT playlists, " +
    "but they are private right now and I still haven't decided whether it would be a good idea to make them public. " +
    "This is still a good starting point to get to know me.",
  key: "a1",
  categories: [
    {
      title: "Hindustani Classical",
      items: [
        {
          label: "Bhimsen Joshi - Raag Bhairav",
          link: "https://youtu.be/NMHoLg5PxRM",
        },
        {
          label: "Prabha Atre - Jagoon Mai Sari Raina",
          link: "https://youtu.be/yFOn2YRj0KY",
        },
        {
          label: "Prabha Atre - Jamuna Kinare Mora Gaon",
          link: "https://youtu.be/aPj8nRdJ3B8",
        },
        {
          label: "Bhimsen Joshi - Payali Jhankar Mori",
          link: "https://youtu.be/dKeSBOU_PDo",
        },
        {
          label:
            "Gangubai Hangal - Raga Bageshree - Khayal Vilambit In Ektaal, Drut In Teentaal",
          link: "https://youtu.be/TU_JPiQXcdY",
        },
        {
          label: "Ustad Amjad Ali Khan - Raag Des, Raag Durga",
          link: "https://youtu.be/3utGH37HzCk",
        },
        {
          label: "Ustad Rashid Khan - Raag Megh",
          link: "https://youtu.be/wsq27ntWHnQ",
        },
      ],
    },
    {
      title: "Western Classical",
      items: [
        {
          label: "Chopin - Nocturne op.9 No.2",
          link: "https://www.youtube.com/watch?v=9E6b3swbnWg",
        },
        {
          label: "Tchaikovsky - Sérénade mélancolique, Op. 26",
          link: "https://youtu.be/4x50QqYO1bI",
        },
      ],
    },
    {
      title: "Hindi Movie Music",
      items: [
        {
          label: "Yeh Jo Des Hai Tera (Swades)",
          link: "https://youtu.be/V1bwI8tGgAY",
        },
        {
          label: "Kyun Main Jagoon (Patiala House)",
          link: "https://youtu.be/lN1m7zLBbSU",
        },
        {
          label: "Judaai  (Badlapur)",
          link: "https://youtu.be/W9JSHZG8PJQ",
        },
        {
          label: "Taare Zameen Par Title Song (Taare Jameen Par)",
          link: "https://youtu.be/kaMB6Rw8XzA",
        },
        {
          label: "Hoshwalon Ko Khabar Kya (Sarfarosh )",
          link: "https://youtu.be/hZuwe72Rtcc",
        },
      ],
    },
    {
      title: "Marathi Natyasangeet (Music from Marathi Musicals)",
      items: [
        {
          label: "Vasantrao Deshpande - Mriganayana Rasik Mohini",
          link: "https://youtu.be/ZtDX1Mt1Ntc",
        },
        {
          label: "Vasantrao Deshpande - Shat Janm Shodhitana",
          link: "https://youtu.be/OH7wf13cJzk",
        },
        {
          label: "Rahul Deshpande - Dil ki Tapish",
          link: "https://youtu.be/GKYc9IPMdn8",
        },
        {
          label: "Rahul Deshpande - Surat Piya Ki",
          link: "https://youtu.be/c-hjEAXOMoY",
        },
      ],
    },
    {
      title: "Alternative Rock / Metal",
      items: [
        {
          label: "Daliborovo Granje - Hainin",
          link: "https://youtu.be/dr6IkPSrvbw",
        },
        {
          label: "Khruangbin & Leon Bridges - Texas Sun",
          link: "https://youtu.be/zSWNWWREtsI",
        },
        {
          label: "Om - Advaitic Songs",
          link: "https://youtu.be/ts3YWVFUnvU",
        },
        {
          label: "Estas Tonne - Internal Flight",
          link: "https://youtu.be/6lIt07sBW4E",
        },
        {
          label: "Heilung - Krigsgaldr",
          link: "https://youtu.be/K7ZqZVunCb4",
        },
      ],
    },
    {
      title: "Assorted English Music",
      items: [
        {
          label: "Black Pumas - Colors",
          link: "https://youtu.be/0G383538qzQ",
        },
        {
          label: "Radiohead - Creep",
          link: "https://youtu.be/XFkzRNyygfk",
        },
        {
          label: "Johnny Cash - Hurt",
          link: "https://youtu.be/8AHCfZTRGiI",
        },
        {
          label: "Pink Floyd - Wish You Were Here",
          link: "https://youtu.be/hjpF8ukSrvk",
        },
        {
          label: "The Rolling Stones - Wild Horses",
          link: "https://youtu.be/SQTHB4jM-KQ",
        },
      ],
    },
    {
      title: "Assorted Hindi Music",
      items: [
        {
          label: "Rabbi Shergill - Tere Bin",
          link: "https://youtu.be/_trU-Wt9ucI",
        },
        {
          label: "Kailash Kher - Teri Deewani",
          link: "https://youtu.be/zZasH6qkn8M",
        },
        {
          label: "Aryans - Aankhon Mein",
          link: "https://youtu.be/roJL3mIueTE",
        },
      ],
    },
  ],
};
