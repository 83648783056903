import React from "react";
import { PrimaryNav } from "../../components";
import {
  Container,
  PageBreak,
  PageContainer,
  PageHeading1,
} from "../../globalStyles";
import profile_image from "../../my_data/profilePicture.jpg";
import { ProfileImage } from "./AboutPageStyle";
import { Link } from "react-router-dom";

export default function AboutPage() {
  return (
    <PageContainer>
      <PrimaryNav currentPage="about" />
      <PageHeading1>About Me</PageHeading1>
      <ProfileImage src={profile_image} alt="Sourav Kulkarni"></ProfileImage>
      <Container>
        <p>
          Hello, this is Sourav Kulkarni. Currently, I'm a MSc Media Informatics
          student at{" "}
          <a
            href="https://www.rwth-aachen.de/"
            target="_blank"
            rel="noreferrer"
          >
            RWTH Aachen
          </a>
          , doing my thesis in NLP at{" "}
          <a
            href="https://www.iais.fraunhofer.de/"
            target="_blank"
            rel="noreferrer"
          >
            Fraunhofer IAIS
          </a>
          . I also work part-time as a front-end developer at{" "}
          <a
            href="https://www.fit.fraunhofer.de/"
            target="_blank"
            rel="noreferrer"
          >
            Fraunhofer FIT
          </a>
          . Previously, I also worked as a Risk Analyst at{" "}
          <a href="https://home.barclays/" target="_blank" rel="noreferrer">
            Barclays
          </a>
          , Pune
        </p>
        <p>
          You can find my resume{" "}
          <a
            href="https://drive.google.com/file/d/1eEQBesRJDWQZxTQUfo8-ptGY9N1rhQ1W/view?usp=share_link"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          Apart from work, I spend some of my free time pursuing my hobbies
          which include trekking,{" "}
          <a
            href="https://www.instagram.com/souravkulkarni/"
            target="_blank"
            rel="noreferrer"
          >
            wildlife photography
          </a>
          , reading, music, podcasts, etc.
          <br></br>
          However, I am a huge promoter of a laid-black lifestyle and take
          things extremely slowly whenever I can. I am rarely active on social
          media and would rather go on long walks whenever I can.
        </p>
        <p>
          You can reach me through my email id:
          <p style={{ fontFamily: "monospace" }}>
            srvklkrn (at) gmail (dot) com
          </p>
        </p>
      </Container>
      <PageBreak></PageBreak>
      <Container>
        Here's some more information about me:
        <ul>
          <li>
            What (<Link to="/about/music">Music</Link>) do I listen to?{" "}
          </li>
          <li>
            What (<Link to="/about/podcasts">Podcasts</Link>) do I listen to?
          </li>
          <li>
            What do I <Link to="/about/books">read</Link>?
          </li>
        </ul>
      </Container>
    </PageContainer>
  );
}
