export const books_i_like = {
  title: "Books I like",
  description:
    "Some selections of books The entire list can be found on my Goodreads page",
  key: "a1",
  categories: [
    {
      title: "Misc. Cat. 1",
      items: [
        {
          label: "Richard Dawkins - The Selfish Gene",
          link: "https://www.goodreads.com/book/show/61535.The_Selfish_Gene",
        },
        {
          label: "Richard Dawkinks - The God Delusion",
          link: "https://www.goodreads.com/book/show/14743.The_God_Delusion",
        },
        {
          label: "J. Sai Deepak - India that is Bharat",
          link: "https://www.goodreads.com/book/show/58508534-india-that-is-bharat",
        },
        {
          label: "Daniel Dennett - From Bacteria to Bach and Back",
          link: "https://www.goodreads.com/book/show/35167699-from-bacteria-to-bach-and-back",
        },
        {
          label: "Steven Levy - Artificial Life",
          link: "https://www.goodreads.com/book/show/737831.Artificial_Life",
        },
        {
          label: "Charlotte Perkins Gilman - Women and Economics",
          link: "https://www.goodreads.com/book/show/600806.Women_and_Economics",
        },
      ],
    },
  ],
};
