import React from "react";
import { Container, PageContainer, PageHeading1 } from "../../globalStyles";
import { PrimaryNav } from "../../components";
import { blog_posts } from "./blog_posts";

export default function BlogPage() {
  return (
    <PageContainer>
      <PrimaryNav currentPage="blog" />
      <PageHeading1>Blog</PageHeading1>
      <Container>
        <p>
          I found it easier to just let Google be generous enough and host the
          blog on their own end, so that I don't have to worry about it. You can
          find it{" "}
          <a
            href="https://srvklkrn.blogspot.com"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . I shall anyway provide a list of all articles on this page.
        </p>
        <ul>
          {blog_posts.posts.map(function (post, index) {
            return (
              <li key={index}>
                <a href={post.link} target="_blank" rel="noreferrer">
                  {post.name}
                </a>{" "}
                - <i>{post.date}</i>
              </li>
            );
          })}
        </ul>
      </Container>
    </PageContainer>
  );
}
