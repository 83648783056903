import styled from "styled-components/macro";

export const PrimaryNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
`;

export const PrimaryNavList = styled.ul`
  list-style-type: none;
  font-size: x-large;
  padding: 0;
  margin: 10px auto;
`;

export const NavItem = styled.li`
  display: inline;
  margin: 0px 10px;

  a {
    color: #383737;
    text-decoration: none;
  }

  a.active {
    // text-decoration: underline;
    font-weight: bold;
  }

  a:hover {
    color: #383737;
  }
`;
