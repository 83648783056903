import React from "react";
import { Link } from "react-router-dom";
import { PageBreak } from "../../globalStyles";
import {
  PrimaryNavList,
  NavItem,
  PrimaryNavContainer,
} from "./PrimaryNavStyle";

export default function PrimaryNav(props) {
  let currentPage = props.currentPage;
  const pageList = [
    {
      location: "/",
      name: "home",
      label: "Home",
    },
    {
      location: "/blog",
      name: "blog",
      label: "Blog",
    },
    {
      location: "/projects",
      name: "projects",
      label: "Projects",
    },
    {
      location: "/about",
      name: "about",
      label: "About",
    },
  ];

  return (
    <PrimaryNavContainer>
      <PrimaryNavList>
        {pageList.map(function (page, index) {
          return (
            <NavItem key={index}>
              {page.name === currentPage ? (
                <Link to={page.location} className="active">
                  {page.label}
                </Link>
              ) : (
                <Link to={page.location}>{page.label}</Link>
              )}
            </NavItem>
          );
        })}
      </PrimaryNavList>
      <PageBreak></PageBreak>
    </PrimaryNavContainer>
  );
}
