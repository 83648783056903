export const podcasts_i_like = {
  title: "Podcasts I like",
  description:
    "Majority of the media I consume is through podcasts - be it in audio of video format. Some selections from the podcasts I listen to/watch.",
  key: "a1",
  categories: [
    {
      title: "Content-based",
      items: [
        {
          label: "Stuff You Should Know",
          link: "https://podcasts.google.com/",
        },
        {
          label: "1001 Short Stories",
          link: "https://podcasts.google.com/",
        },
        {
          label: "True Crime Podcasts",
          link: "https://podcasts.google.com/",
        },
        {
          label: "Freakonomics Radio",
          link: "https://podcasts.google.com/",
        },
        {
          label: "My Favorite Theorem",
          link: "https://podcasts.google.com/",
        },
        {
          label: "Rationally Speaking",
          link: "https://podcasts.google.com/",
        },
        {
          label: "What was that like?",
          link: "https://podcasts.google.com/",
        },
        {
          label: "This American Life",
          link: "https://podcasts.google.com/",
        },
        {
          label: "Behind the Bastards",
          link: "https://podcasts.google.com/",
        },
        {
          label: "Philosophise This",
          link: "https://podcasts.google.com/",
        },
      ],
    },
    {
      title: "Guest-based",
      items: [
        {
          label: "Lex Fridman Podcast",
          link: "https://podcasts.google.com/",
        },
        {
          label: "The Joe Rogan Experience",
          link: "https://podcasts.google.com/",
        },
        {
          label: "Carvaka Podcast",
          link: "https://podcasts.google.com/",
        },
      ],
    },
  ],
};
