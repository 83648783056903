import React from "react";
import { PrimaryNav } from "../../components";
import { Container, PageContainer, PageHeading2 } from "../../globalStyles";
import { books_i_like } from "../../my_data/books_i_like";
import { Link } from "react-router-dom";

export default function BooksPage() {
  return (
    <PageContainer>
      <PrimaryNav currentPage="about" />
      <PageHeading2>Books Preferences</PageHeading2>
      <Container>
        <p>
          {" "}
          As I've said on the other page, I prefer audio(
          <Link to="/about/podcasts">Podcasts</Link>) and video(YouTube) way
          more that books. But here's a few of my favorite books. There's an
          entire list on my{" "}
          <a
            href="https://www.goodreads.com/user/show/122623881-sourav-kulkarni"
            target="_blank"
            rel="noreferrer"
          >
            GoodReads
          </a>{" "}
          page which I haven't decided whether I should make public or not/
        </p>
      </Container>
      <div>
        {/* <h3>{books_i_like.title}</h3> */}
        {/* <p>{books_i_like.description}</p> */}
        {books_i_like.categories.map(function (category, index) {
          return (
            <div>
              <h4 key={index}>{category.title}</h4>
              <p>{category.description}</p>
              <ul>
                {category.items.map(function (item, index) {
                  return (
                    <li key={index}>
                      {/* <a href={item.link} target="_blank" rel="noreferrer"> */}
                      {item.label}
                      {/* </a> */}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
}
