import React from "react";
import { PrimaryNav } from "../../components";
import { PageContainer } from "../../globalStyles";
import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <PageContainer>
      <PrimaryNav currentPage="home" />
      <p>
        Hello, this is Sourav Kulkarni and you have arrived at my personal
        website. You can find more information about me{" "}
        <Link to="/about">here</Link>.
      </p>
    </PageContainer>
  );
}
