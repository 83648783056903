import { GlobalStyle } from "./globalStyles";
import {
  HomePage,
  BlogPage,
  ProjectPage,
  AboutPage,
  MusicPage,
  PodcastsPage,
  BooksPage,
  NoPage,
} from "./pages";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <NoPage />,
    },
    {
      path: "blog",
      element: <BlogPage />,
    },
    {
      path: "projects",
      element: <ProjectPage />,
    },
    {
      path: "about",
      element: <AboutPage />,
    },
    {
      path: "about/music",
      element: <MusicPage />,
    },
    {
      path: "about/podcasts",
      element: <PodcastsPage />,
    },
    {
      path: "about/books",
      element: <BooksPage />,
    },
  ]);

  return (
    <div className="App">
      <GlobalStyle />
      {/* <header className="App-header"></header> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
