import React from "react";
import { PrimaryNav } from "../../components";
import {
  Container,
  PageBreak,
  PageContainer,
  PageHeading2,
} from "../../globalStyles";
import { music_i_like } from "../../my_data/music_i_like";

export default function MusicPage() {
  return (
    <PageContainer>
      <PrimaryNav currentPage="about" />
      <PageHeading2>Music Preferences</PageHeading2>
      <Container>
        <p>
          <ul>
            <li>
              Favourite Hindustani Classical Raag :{" "}
              <a
                href="https://youtu.be/vAzW1c--LqY"
                target="_blank"
                rel="noreferrer"
              >
                Raag Bhairav
              </a>
            </li>
            <li>
              Favourite Hindustani Classical Singer (Male) : Bhimsen Joshi
            </li>
            <li>
              Favourite Hindustani Classical Singer (Female) : Prabha Atre
            </li>
            <li>Favourite Western Classical Composer : Chopin</li>
            <li>
              Favourite piece of Western Classic Music :{" "}
              <a
                href="https://youtu.be/9E6b3swbnWg"
                target="_blank"
                rel="noreferrer"
              >
                Chopin - Nocturne op.9 No.2
              </a>
            </li>
            <li>(Current) favorite instrument : Sarangi</li>
          </ul>
        </p>
      </Container>
      <PageBreak></PageBreak>
      <div>
        <h3>{music_i_like.title}</h3>
        <p>{music_i_like.description}</p>
        {music_i_like.categories.map(function (category, index) {
          return (
            <div>
              <h4 key={index}>{category.title}</h4>
              <p>{category.description}</p>
              <ul>
                {category.items.map(function (item, index) {
                  return (
                    <li key={index}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        {item.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
}
