export const projects_data = {
  projects: [
    {
      name: "Representative Politics",
      date: "July 2021",
      link: "https://souruly.github.io/P5-Playground/Representative_Politics/index.html",
    },
    {
      name: "Generalized Bezier",
      date: "November 2020",
      link: "https://souruly.github.io/P5-Playground/Generalized_Bezier/",
    },
    {
      name: "Cubic Hermite Polynomial Interpolation",
      date: "November 2020",
      link: "https://souruly.github.io/P5-Playground/Cubic_Hermite/",
    },
    {
      name: "Scanimation",
      date: "October 2020",
      link: "https://souruly.github.io/P5-Playground/Scanimation/",
    },
    {
      name: "Bubbles",
      date: "September 2020",
      link: "https://souruly.github.io/P5-Playground/Bubbles/",
    },
    {
      name: "Art with Oscillators and Spinners",
      date: "April 2020",
      link: "https://souruly.github.io/P5-Playground/Tandem_Oscillators/index.html",
    },
    {
      name: "Scotland Yard Helper",
      date: "March 2020",
      link: "https://github.com/Souruly/Scotland-Yard-Helper",
    },
    {
      name: "Covid-19 Spread Simulator",
      date: "March 2020",
      link: "https://souruly.github.io/P5-Playground/Corona_Spread_Simulation/index.html",
    },
    {
      name: "Product Quality Control",
      date: "August 2019",
      link: "https://ieeexplore.ieee.org/abstract/document/8844942",
    },
    {
      name: "Text Image Generator",
      date: "February 2019",
      link: "https://souruly.github.io/P5-Playground/ASCII_Art/index.html",
    },
    {
      name: "Capture-Recapture Estimations",
      date: "February 2019",
      link: "https://souruly.github.io/P5-Playground/Capture_Recapture/index.html",
    },
    {
      name: "Depression Simulator",
      date: "August 2018",
      link: "https://souruly.github.io/P5-Playground/Depression/index.html",
    },
    {
      name: "The Path of Life",
      date: "July 2018",
      link: "https://souruly.github.io/P5-Playground/Life/index.html",
    },
    {
      name: "Anand Bhai",
      date: "May 2018",
      link: "https://github.com/Souruly/Processing-Projects/tree/main/Anand%20Bhai/Anand_Bhai",
    },
    {
      name: "Image Artifact Removal",
      date: "December 2017",
      link: "https://github.com/Souruly/DIP---Image-Artifact-Removal/tree/master/DIP%20-%20Object%20Removal%20with%20Mode",
    },
    {
      name: "OpenCV Theremin",
      date: "November 2017",
      link: "https://github.com/Souruly/Processing-Projects/tree/main/PS3%20Move%20Controller%20Theremin/Theremin3",
    },
    {
      name: "Generative Art",
      date: "November 2017",
      link: "https://souruly.github.io/P5-Playground/Generative_Art/index.html",
    },
    {
      name: "Low Res Lightening Simulator",
      date: "October 2017",
      link: "https://souruly.github.io/P5-Playground/Lightening/index.html",
    },
    {
      name: "Rain",
      date: "August 2017",
      link: "https://github.com/Souruly/Processing-Projects/tree/main/Rain/RainV5",
    },
    {
      name: "Eyes!",
      date: "August 2017",
      link: "https://souruly.github.io/P5-Playground/Eyes/index.html",
    },
    {
      name: "Simple Projectile Motion",
      date: "August 2017",
      link: "https://souruly.github.io/P5-Playground/Simple_Projectile_Motion/index.html",
    },
    {
      name: "Arduino Exercise Bike Game",
      date: "July 2017",
      link: "https://github.com/Souruly/Processing-Projects/tree/main/Arduino%20Exercise%20Bike%20Game/TEDxPICT%20Installation",
    },
    {
      name: "Binary Tree",
      date: "March 2017",
      link: "https://souruly.github.io/P5-Playground/Binary_Tree/index.html",
    },
    {
      name: "Old Bio Projects",
      date: "< 2018",
      link: "https://souruly.github.io/Bio/",
    },
  ],
  reports: [
    {
      name: "India's Aadhaar Platform",
      date: "September 2022",
      link: "https://drive.google.com/file/d/1YvY9LIyqLf_Qzmd41RhrAuNPUYRqH2ML/view?usp=sharing",
    },
    {
      name: "Citation Curve Analysis",
      date: "January 2022",
      link: "https://drive.google.com/file/d/1Lo9lTAzEQ2W8CGrUmqb4tfGS-rlGfCDb/view?usp=sharing",
    },
    {
      name: "Imperfect Bottle Cap Fitting Detection",
      date: "August 2019",
      link: "https://ieeexplore.ieee.org/abstract/document/8844942",
    },
    {
      name: "Image Inpainting",
      date: "November 2018",
      link: "https://ictactjournals.in/paper/IJIVP_Vol_9_Iss_2_Paper_6_1887_1893.pdf",
    },
  ],
};
